


import React from 'react';
import './style.css';

function words() {
  return (


 
    <div className="content" id="ok">
    <h1>MaiL Poem</h1>
    <p>World falling apart. Everlasting memory. Change is blowing in the wind. </p>
  </div>
  

  );
}

export default words;
