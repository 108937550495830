import React from 'react';
import './style.css';

function Nav() {
  return (


 
    <div className="content" id="nav">
      <ul id="navUL">
    <li>MaiL Poem</li>
    <li>things</li>
    <li>things</li>
    <li>things</li>
   <li id="settings">Settings</li>
   </ul>
  </div>
  

  );
}

export default Nav;
